@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-react-pivotview/styles/material.css';

.e-pivotview .e-columnsheader {
    text-align: center !important;
}

.e-pivotview .e-altrow {
    background-color: #ebe9e9 !important;
}
/* TODO: commented out because the values have changed - can be put back in to match */
/* id 12 */
/* .e-pivotview td[aria-label="2.99% column header Fraud %"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="$457,333 column header Billing In $"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="$13,667 column header Fraud In $"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="3.12 column header Index"] {
    background-color: #e94545a6;
} */

/* id 13 */
/* .e-pivotview td[aria-label="411 column header Credit"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="1,295 column header Debit"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="438 column header Marginal Bins"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="21.8% column header % Credit"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="65.5% column header % Debit"] {
    background-color: #e94545a6;
}
.e-pivotview td[aria-label="12.7% column header % Marginal Bins"] {
    background-color: #e94545a6;
} */